<template>
  <div
    v-if="page"
    class="add-new-item-admin"
  >
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.pageUpdate')"
      :title="$t('messages.pageUpdate')"
      @submitPressed="submitPressed"
    />
    <page-form :page-obj="page" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import PageForm from './components/PageForm.vue';

export default {
  components: {
    TitleBar,
    PageForm
  },
  data() {
    return {
      action: null,
      page: null
    }
  },
  created() {
    this.loadPage()
  },
  methods: {
    loadPage() {
      const id = this.$route.params.id;
      this.$WebPages.getResource({ id }).then((response) => {
        this.page = response.data
        if(response.data.parent) {
          this.page.parent = {
            id: response.data.parent,
            label: response.data.parentName
          }
        }
      });
    },
    submitPressed() {
      this.action = 'update'
    }
  }
};
</script>

<style lang="scss">
</style>
